/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 16번째 로그에서는 han 님과 함께 정부의 SNI 기반 인터넷 접속 차단, 아이폰 앱의 무단 화면 촬영, CamelCamelCamel 다운에 대해서 이야기했습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.buymeacoffee.com/stdoutfm"
  }, "Buy stdout.fm a Coffee - BuyMeACoffee.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hanbit.co.kr/rent/facilities.html"
  }, "한빛출판네트워크 | 대관 서비스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kcc.go.kr/user.do?boardId=1113&page=A05030000&dc=K00000001&boardSeq=46820&mode=view"
  }, "방송통신위원회 - 불법정보를 유통하는 해외 인터넷사이트 차단 강화로 피해구제 확대")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.clien.net/service/board/park/13152618?od=T33&po=0&category=&groupCd="
  }, "HTTPS/DNS 차단 쉽게 이해하기 : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/kid1ng/status/992746221088358400"
  }, "@kid1ng on Twitter: \"S. Korea: Censorship Checklist ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.sbs.co.kr/news/endPage.do?news_id=N1005133315"
  }, "정부 \"불법 사이트 차단 강화\"…자유 침해·감청 논란도")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EA%B8%88%EC%88%9C%EA%B3%B5%EC%A0%95"
  }, "금순공정 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zonena.me/2013/09/how-the-nsa-is-breaking-ssl/"
  }, "How the NSA is breaking SSL")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.chosun.com/site/data/html_dir/2019/02/08/2019020801572.html"
  }, "'화웨이 압박' 강도 높이는 美…“中 통신장비 금지 행정명령” - 조선닷컴 - 국제 > 중국")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.boannews.com/media/view.asp?idx=56711"
  }, "양자 컴퓨팅은 브라우저 암호화를 어떻게 바꾸는가")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Server_Name_Indication"
  }, "Server Name Indication - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.law.go.kr/lsEfInfoP.do?lsiSeq=61603#"
  }, "국가법령정보센터 | 법령 > 본문 - 대한민국헌법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mcst.go.kr/kor/s_notice/press/pressView.jsp?pMenuCD=0302000000&pSeq=16672"
  }, "보도자료 - 웹툰 등 불법유통 해외사이트 집중 단속 및 정품 이용 캠페인 연계 실시 | 문화체육관광부")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bloter.net/archives/184232"
  }, "위키리크스 창시자, “정부 비밀 추가 폭로할 것”")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.naver.com/main/read.nhn?mode=LSD&mid=sec&sid1=100&oid=053&aid=0000025669"
  }, "런던 통신 - 영국이 주민등록 안 만드는 이유 : 네이버 뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.naver.com/main/read.nhn?mode=LSD&mid=sec&sid1=100&oid=053&aid=0000025720"
  }, "런던 통신 - 영국인이 주민등록 안 만드는 이유 2탄 : 네이버 뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/perillamint/status/1095289757016485888"
  }, "perillamint🧝🧙⚧ on Twitter: \"알고 계십니까: 독일의 여권 발급이 굉장히 느린 이유는...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www1.president.go.kr/petitions/219367"
  }, "문체부가 추진하는 https 사이트 차단 계획의 철회를 요청합니다. > 국민청원 > 대한민국 청와대")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www1.president.go.kr/petitions/522031?navigation=best-petitions&fbclid=IwAR2UaRd1dc9uo28ZWp2WPOc3FuGs9rX2qTtmNKIrX7hwUnE_oM-OIfQ8B8M"
  }, "https 차단 정책에 대한 반대 의견 > 국민청원 > 대한민국 청와대")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cloudflare.com/learning/dns/what-is-1.1.1.1/"
  }, "What is 1.1.1.1? | Cloudflare")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://itunes.apple.com/us/app/1-1-1-1-faster-internet/id1423538627?mt=8"
  }, "‎1.1.1.1: Faster Internet on the App Store")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.cloudflare.com/1.1.1.1/dns-over-https/"
  }, "DNS over HTTPS - Cloudflare Resolver")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ciokorea.com/news/32265"
  }, "애플 보안 요건 강화 코앞인데··· \"iOS 앱 97%, 아직 준비 안돼\" - CIO Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.mozilla.org/security/2018/10/18/encrypted-sni-comes-to-firefox-nightly/"
  }, "Encrypted SNI Comes to Firefox Nightly | Mozilla Security Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://datatracker.ietf.org/doc/draft-ietf-tls-esni/"
  }, "draft-ietf-tls-esni-02 - Encrypted Server Name Indication for TLS 1.3")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cloudflare.com/ssl/encrypted-sni/"
  }, "Cloudflare ESNI Checker | Cloudflare")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/encrypt-that-sni-firefox-edition/"
  }, "Encrypt that SNI: Firefox edition")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=7V2aUErljjU"
  }, "SNI 차단 우회 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kmib.co.kr/article/view.asp?arcid=0923781697"
  }, "中, VPN 차단 명령… 인터넷 통제 ‘우회로’마저 봉쇄-국민일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/esni/"
  }, "Encrypting SNI: Fixing One of the Core Internet Bugs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/society/society_general/684030.html"
  }, "‘레진코믹스’ 예고없이 접속 차단했다가 하루 만에… : 사회일반 : 사회 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kocsc.or.kr/cmm/fms/FileDown.do?atchFileId=FILE_OLD_8730&fileSn=0"
  }, "통신심의소위원회 회의록게시판 2015년 22차 회의록")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kocsc.or.kr/cop/bbs/selectBoardArticle.do"
  }, "통신심의소위원회 회의록게시판 2015년 23차 회의록")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.law.go.kr/lsInfoP.do?lsiSeq=181624#0000"
  }, "국가법령정보센터 | 법령 > 본문 - 국민보호와 공공안전을 위한 테러방지법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/politics/assembly/659858.html"
  }, "야 의원 카톡방 썰물…텔레그램에 새 둥지 : 국회·정당 : 정치 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/372300"
  }, "1984 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2019/02/06/iphone-session-replay-screenshots/"
  }, "Many popular iPhone apps secretly record your screen without asking | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://theappanalyst.com/aircanada.html"
  }, "App Analysis: Air Canada")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.glassboxdigital.com/"
  }, "Innovative Digital Customer Experience Solutions | Glassbox")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.glassboxdigital.com/message-cto-part1/"
  }, "A message from Glassbox CTO and Co-Founder Yaron Gueta - Part 1 | Glassbox")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/HTTP_Public_Key_Pinning"
  }, "HTTP Public Key Pinning - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.koreatimes.net/ArticleViewer/Article/112731"
  }, "캐나다 한국일보 : 에어캐나다 모바일앱 해킹")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.glassboxdigital.com/press-release/glassbox-signs-new-contract-with-air-canada-to-optimise-cross-channel-online-customer-experience/"
  }, "Glassbox Signs New Contract with Air Canada to Optimise Cross-channel Online Customer Experience | Glassbox")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://takagi-hiromitsu.jp/diary/20090315.html"
  }, "(일본어) 도쿄 지하철에서 블루투스 탐사 - 다카기 히로미츠")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://walkinsights.com/"
  }, "워크 인사이트 - 매장을 위한 빅데이터 서비스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.mk.co.kr/newsRead.php?year=2010&no=433900"
  }, "MK News - 구글 스트리트뷰에 뚫려…와이파이 보안 도마위에")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reddit.com/r/DataHoarder/comments/altdbo/camelcamelcamelcom_data_failure_an_insight_into/efht6gz/"
  }, "CamelCamelCamel.com Data Failure - An insight into recovery and failsafe : DataHoarder")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/news/13137588"
  }, "아마존 가격이력 사이트 '낙타', 사이트 일시 다운돼 : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.samsung.com/sec/memory-storage/860-pro-sata-3-2-5-inch-ssd/MZ-76P2T0BW/"
  }, "SSD 860 PRO 2 TB | MZ-76P2T0BW | Samsung 대한민국")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/4667932"
  }, "대규모 서비스를 지탱하는 기술 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://whooing.com/"
  }, "후잉가계부(whooing)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.backblaze.com/blog/hard-drive-stats-for-2017/"
  }, "2017 Hard Drive Failure Rate Comparison")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.computerweekly.com/news/2240082860/Disaster-planning-and-business-continuity-after-9-11"
  }, "Disaster planning and business continuity after 9/11")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
